<div class="recorder-section" *ngIf="videoMode === videoModeEnum.record">

  <video #videoElement
         class="video-player"
  ></video>

  <div class="recorder">
    <div class="recorder-wrapper">
      <div class="countdown-overlay" *ngIf="countdownSettings.displayOverlay && countdownSettings.enable">
        <div class="countdown-container">
          <span class="countdown-time">{{ countdownSettings.currentTime }}</span>
        </div>
      </div>
      <div #recorder class="flashphoner-display-element"></div>
    </div>
  </div>

  <div class="controls-wrapper">
    <div class="question">{{ question }}</div>

    <div class="progress-tracker-container">
      <div class="progress-tracker">
        <div class="progress"
              [ngStyle]="{width: recordingProgress + '%'}"></div>
        <div class="track"></div>
        <div class="indicator"
              [ngStyle]="{left: 'calc(' + recordingProgress + '% - 1.1rem)'}"></div>
      </div>
    </div>

    <div class="controls-section">
      <div class="controls full-width">
        <button [disabled]="!readyToRecord"
                *ngIf="!recordingInProgress"
                (click)="startRecording()"
                class="btn-icon">
          <i class="icon-circle"></i>
        </button>
        <button [disabled]="videoProgress < 3"
                *ngIf="recordingInProgress"
                (click)="stopRecording()"
                class="btn-icon">
          <i class="icon-square"></i>
        </button>
      </div>
    </div>

    <div class="back-btn-wrapper" *ngIf="videoMode === videoModeEnum.record && readyToRecord">
      <i class="icon-arrow-left" (click)="back()"></i>
    </div>
  </div>

</div>

<div class="player-section" *ngIf="videoMode === videoModeEnum.review">
  <app-video-replay [useWebmVideo]="true"
                    [video]="videoMp4Url"
                    [useCenteredVideo]="false"
                    [useCustomControls]="false"
                    [showBackgroundImage]="false"
                    [useControls]="true"></app-video-replay>
  <div class="controls-section">
    <div class="controls playback">
      <button (click)="removeVideo()"
                  class="btn-secondary">{{ 'BUTTONS.REDO' | translate }}</button>
      <button [disabled]="disableUploadButton" (click)="submitAnswer()"
              class="btn-primary">{{ 'BUTTONS.SUBMIT' | translate }}</button>
    </div>
  </div>
</div>

<ng-container *ngIf="videoMode === videoModeEnum.void">
  <div class="initial-screen">
    <div class="question">{{ question }}</div>

    <div class="controls-section">
      <div class="controls">
        <button *ngIf="job.uploadVideoAnswers" (click)="openUploadVideoModal()"
                class="btn-secondary">{{ 'VIDEO.UPLOAD_ANSWER' | translate }}</button>
        <button *ngIf="job.linkVideoAnswers" [disabled]="disableUploadButton" (click)="openAddLinkModal()" class="btn-secondary">
          {{ 'VIDEO.ADD_LINK' | translate }}
        </button>
        <button *ngIf="job.streamVideoAnswers" [disabled]="disableUploadButton" (click)="record()"
                class="btn-primary">{{ 'VIDEO.RECORD_ANSWER' | translate }}</button>
      </div>
      <div class="invalid-feedback" *ngIf="showDurationError">
        {{ 'VIDEO.MAXIMUM_VIDEO_DURATION' | translate }}
      </div>
    </div>

    <div class="upload-progress-bar" *ngIf="uploadInProgress">
      <div class="custom-progress-bar-wrapper">
        <div class="custom-progress-bar" [ngStyle]="{width: uploadProgress + '%'}"></div>
        <div class="percent">{{ uploadProgress + '%' }}</div>
      </div>
      <div class="optimization-in-progress" *ngIf="uploadInProgress && inProcessing">
        {{ 'VIDEO.OPTIMIZATION_IN_PROCESS' | translate }}
      </div>

      <button class="btn-secondary" (click)="cancelUpload()">{{ 'BUTTONS.CANCEL' | translate }}</button>
    </div>
  </div>

  <input #videoUploadInput
         hidden
         type="file"
         [accept]="allowedFileTypes.join(', ')"
         (change)="uploadVideo($event.target)" />
</ng-container>

<button class="btn-link"
        *ngIf="(loader | async) && videoMode === videoModeEnum.record && showBackToUploadButton"
        (click)="back()">
  {{ 'VIDEO.RETURN_TO_UPLOAD' | translate }}
</button>
