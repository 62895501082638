<div class="video-intro-message">
  <h2 [ngClass]="{'universal-job': isUniversalJobUpdate}">
    {{ (application.videoQuestions.videoMessage || ('VIDEO.INTRO_MESSAGE_TITLE' | translate)) | titlecase }}
  </h2>
  <div [innerHTML]="sanitizeContent()"></div>
  <div class="battery-saver-notice" *ngIf="isMobile">
    {{ 'VIDEO.NOTICE' | translate }} {{ 'VIDEO.BATTERY_SAVER_NOTICE_MSG' | translate }}
  </div>
</div>

<app-video-replay [video]="videoUrl"
                  *ngIf="videoUrl"
                  [useCenteredVideo]="false"
                  [useCustomControls]="false"
                  [showBackgroundImage]="false"
                  [useControls]="true">
</app-video-replay>

<button *ngIf="!isUniversalJobUpdate"
        class="btn-secondary"
        (click)="answerQuestions()">
  {{ 'BUTTONS.START' | translate }}
</button>
