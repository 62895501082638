<div class="modal-window">
  <div class="modal-window-header">
    <h3 class="modal-window-title">
      {{ data.title | translate }}
    </h3>
    <button type="button"
            (click)="close()">X</button>
  </div>
  <div class="modal-window-body">
    <div class="modal-window-content" *ngIf="data.content">
      <div class="content">{{ data.content | translate }}</div>
    </div>
    <div class="form-group checkbox-list" *ngIf="data.list?.length">
      <label class="custom-checkbox select-all">
        <input
          type="checkbox"
          [checked]="selectAllChecked()"
          (change)="onSelectAllChange($event.target.checked)">
        <span>{{ 'SHARED.SELECT_ALL_JOBS' | translate }}</span>
        <span class="checkmark square"></span>
      </label>
      <div *ngFor="let item of data.list; let i = index">
        <label class="custom-checkbox">
        <input
          [value]="item.id"
          type="checkbox"
          [checked]="item.checked"
          (change)="onChecked($event.target.checked, i)">
        <span>{{item.jobTitle}}</span>
        <span>{{ 'SHARED.LOCATION' | translate }}: {{ item.jobLocation.name[item.company.language] | jobLocation: true }}</span>
        <span class="checkmark square"></span>
      </label>
      </div>
    </div>
    <div class="modal-window-content ql-editor" *ngIf="data.htmlContent">
      <div class="content" [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.htmlContent)"></div>
    </div>
    <form class="modal-window-content" *ngIf="data.form" [formGroup]="data.form">
      <div class="form-group">
        <input type="text"
                class="form-control"
                formControlName="link"
                placeholder="{{ 'VIDEO.VIDEO_LINK' | translate }}" />
        <div class="invalid-feedback" *ngIf="data.form.get('link').invalid && data.form.get('link').touched">
          <div *ngIf="data.form.get('link').errors?.required">
            {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
          </div>
          <div *ngIf="data.form.get('link').errors?.invalidLink && !data.form.get('link').errors?.required">
            {{ 'VIDEO.ADD_VALID_LINK_MESSAGE' | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer-content">
    <button (click)="close()"
            *ngIf="!data.hideCancelButton"
            class="btn-secondary">{{ data.cancelBtnTitle | translate }}</button>
    <button (click)="confirm()"
            *ngIf="!data.hideConfirmButton"
            class="btn-primary">{{ data.confirmBtnTitle | translate }}</button>
  </div>
</div>
