import { JobApplication } from 'src/app/models/job-application.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { readQueryParameters } from '../../shared-functions';
import { map } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { Pagination } from 'src/app/models/pagination.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private _mediaServerUrl = `https://${environment.mediaServerUrl}`;
  private _restPort = '8444';
  private _apiToken = 'aGlnaGVyOjM0QGdoODcza3llNyEzaDR1NQ==';
  private _uploadToken = 'T-49d2016b-e492-4851-b9b4-d103244a2fdd';

  private _questionAnswer$: Subject<JobApplication> = new Subject<JobApplication>();

  constructor(
    private http: HttpClient
  ) { }

  get questionAnswered$(): Observable<JobApplication> {
    return this._questionAnswer$.asObservable();
  }

  set questionAnswered(questionAnswerResponse: JobApplication) {
    this._questionAnswer$.next(questionAnswerResponse);
  }

  submitAnswer(videoKey: string, questionIndex: number): Observable<JobApplication> {
    const guid = readQueryParameters('application');
    return this.http
      .put(
        `${environment.applications}/${guid}/video_questions/save_answer`,
        {
          videoQuestion: 'q' + (questionIndex + 1),
          videoAnswer: videoKey
        }
      )
      .pipe(
        map(({data}: Pagination<JobApplication>) => data[0])
      );
  }

  removeVideo(videoUrl: string): Observable<any> {
    if (!videoUrl) {
      return of(true);
    }

    const tmp = videoUrl.split('/');
    const videoName = tmp[tmp.length - 1];

    return this.http.delete(`${this._mediaServerUrl}/rest/defaultHigher/delete/${videoName}`);
  }

  startRecording(streamId: string, streamName: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Basic ${this._apiToken}`
    });

    return this.http
      .post(
        `${this._mediaServerUrl}:${this._restPort}/rest-api/stream/startRecording`,
        { mediaSessionId: streamId } ,
        { headers, responseType: 'text' }
      ).pipe(
        map(() => {
          const fileName = `stream-${streamName}-${streamId}`;

          return `${this._mediaServerUrl}/records/${fileName}`;
        })
      );
  }

  uploadVideo(file: File): Observable<HttpEvent<string>> {
    const applicationGUID = readQueryParameters('application');
    const headers = new HttpHeaders({
      apptoken: applicationGUID
    });

    const formData = new FormData();
    formData.append('nativeCam', file);

    return this.http
      .post(
        `${this._mediaServerUrl}/rest/defaultHigher/uploadCamera`,
        formData,
        {
          headers,
          responseType: 'text',
          reportProgress: true,
          observe: 'events'
        }
      );
  }
}
