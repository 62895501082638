import { Injectable, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from '../loader.service';
import * as flashphoner from 'src/assets/flashphoner/flashphoner.min.js';
import { STAGING_STREAMING_KEY } from '../../models/video.interface';
import { readQueryParameters } from '../../shared-functions';
import { CheckDeviceService } from '../check-device.service';

@Injectable({providedIn: 'root'})
export class FlashphonerService {

  private _mediaServerUrl = `wss://${environment.mediaServerUrl}:8443`;
  private _sdpMinBitrate = '600';

  private _streamName: string;

  private _constraints = {
    customStream: null,
    video: null,
    audio: false
  };

  private readonly constraints: any = {
    video: {
      width: { min: 480, ideal: 640, max: 1024 },
      height: { min: 360, ideal: 480, max: 768 },
    },
    audio: true
  };

  private _lowConnectionQuality = false;
  private _connectionQualityChecked = false;
  private _reduceVideoResolution = false;

  private _stream: any;
  private _session: any;

  private readonly SESSION_STATUS = flashphoner.constants.SESSION_STATUS;
  private readonly STREAM_STATUS = flashphoner.constants.STREAM_STATUS;
  private readonly CONNECTION_QUALITY = flashphoner.constants.CONNECTION_QUALITY;

  private _videoStreamingComplete$: Subject<void> = new Subject<void>();
  private _lowConnectionQuality$: Subject<void> = new Subject<void>();
  private _connectionFailed$: Subject<'session' | 'stream'> = new Subject<'session' | 'stream'>();
  private _readyToRecord$: Subject<void> = new Subject<void>();

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private checkDevice: CheckDeviceService
  ) {}

  get videoStreamingComplete$(): Observable<void> {
    return this._videoStreamingComplete$.asObservable();
  }

  get lowConnectionQuality$(): Observable<void> {
    return this._lowConnectionQuality$.asObservable();
  }

  get readyToRecord$(): Observable<void> {
    return this._readyToRecord$.asObservable();
  }

  get connectionFailed$(): Observable<'session' | 'stream'> {
    return this._connectionFailed$.asObservable();
  }

  get stream(): any {
    return this._stream;
  }

  initialize(): boolean {
    try {
      flashphoner.init({
        flashMediaProviderSwfLocation: 'https://customer.uhigher.com/assets/flashphoner/media-provider.swf',
        logger: {severity: 'WARN'}
      });
      return true;
    } catch (error) {
      console.error(error);
      this.toastr.error(
        this.translateService.instant('VIDEO.TOAST_ERROR_VIDEO_STREAMING_FAILED_TO_INIT')
      );
      return false;
    }
  }

  playFirstVideo(recorder: HTMLDivElement): Promise<any> {
    return flashphoner.playFirstVideo(
      recorder,
      true,
      'https://customer.uhigher.com/assets/videos/preloader.mp4'
    );
  }

  setConstraintsWithoutCustomStream(recorder: HTMLDivElement, renderer?: Renderer2): void {
    const { height, width } = this.constraints.video;

    const videoWidth = this._reduceVideoResolution ? width.min : width.ideal;
    const videoHeight = this._reduceVideoResolution ? height.min : height.ideal;
    let aspectRatio: number;

    if (this.checkDevice.isiOS() && this.checkDevice.getiOSVersionAsNumber() >= 16) {
      this._constraints = {
        customStream: null,
        video: { width: videoHeight, height: videoWidth },
        audio: true
      };

      aspectRatio = videoHeight / videoWidth;
    } else {
      this._constraints = {
        customStream: null,
        video: { width: videoWidth, height: videoHeight },
        audio: true
      };

      aspectRatio = width / height;
    }

    renderer.setStyle(recorder.parentElement.parentElement, 'aspect-ratio', aspectRatio);
  }

  createSession(recorder: HTMLDivElement, renderer?: Renderer2, userMedia?: MediaStream): void {
    // const { width, height } = userMedia.getVideoTracks()[0].getSettings();
    this.setConstraintsWithoutCustomStream(recorder, renderer);

    this._streamName = this.createUUID(8);

    const appToken = environment.production
      ? readQueryParameters('application')
      : STAGING_STREAMING_KEY;

    flashphoner
      .createSession({
        urlServer: `${this._mediaServerUrl}/${this._streamName}`,
        appKey: 'QS!Hc3&9f9bcmb@RHg&&z1!e',
        custom: {
          appToken,
          userAgent: navigator.userAgent
        }
      })
      .on(this.SESSION_STATUS.ESTABLISHED, (session: any) => {
        this._session = session;
        this.startStream(recorder);
      })
      .on(this.SESSION_STATUS.DISCONNECTED, () => {
        this._session = null;

        if (this._lowConnectionQuality) {
          this._lowConnectionQuality = false;
          this._reduceVideoResolution = true;
          this._lowConnectionQuality$.next();
        }

        this.loaderService.hide();
      })
      .on(this.SESSION_STATUS.FAILED, () => {
        this._session = null;
        this._connectionFailed$.next('session');
        this.loaderService.hide();
      });
  }

  private startStream(recorder: HTMLDivElement): void {
    this._stream = this._session
      .createStream({
        custom: {
          userAgent: navigator.userAgent
        },
        name: this._streamName,
        display: recorder,
        disableConstraintsNormalization: true,
        receiveVideo: false,
        receiveAudio: false,
        transport: 'UDP',
        constraints: this._constraints,
        // stripCodecs: 'H264', for canvas streaming on desktop chrome >=104
        sdpHook: ({sdpString}: {sdpString: string}) => {
          const sdpStringFind = 'a=fmtp:(.*) (.*)';
          const sdpStringReplace = `a=fmtp:$1 $2;x-google-max-bitrate=5000;x-google-min-bitrate=${this._sdpMinBitrate}`;
          let newSdp = sdpString;
          newSdp = newSdp.replace(new RegExp(sdpStringFind, 'g'), sdpStringReplace);

          return newSdp;
        },
      })
      .on(this.STREAM_STATUS.PUBLISHING, () => {
        this._readyToRecord$.next();
        this.loaderService.hide();
      })
      .on(this.STREAM_STATUS.UNPUBLISHED, () => {
        this._stream = null;
        this._session.disconnect();
        this._videoStreamingComplete$.next();
      })
      .on(this.STREAM_STATUS.FAILED, () => {
        this._stream = null;
        this._session.disconnect();
        this.loaderService.hide();
        this._connectionFailed$.next('stream');
      })
      .on(this.CONNECTION_QUALITY.UPDATE, (streamQuality: string) => {
        // console.log(streamQuality, 'quality');
        if (this._connectionQualityChecked) {
          return;
        }

        this._connectionQualityChecked = true;

        if (streamQuality === this.CONNECTION_QUALITY.BAD) {
          this.loaderService.show();
          this._lowConnectionQuality = true;
          this._stream.stop();
        }

        // if (streamQuality === this.CONNECTION_QUALITY.BAD) {
        //   this.toastr.error(this.translateService.instant('VIDEO.LOW_CONNECTION_QUALITY'));
        //   this.loaderService.show();
        //   this._lowConnectionQuality = true;
        //   this._stream.stop();
        // }
      });

    this._stream.publish();
  }

  private createUUID(length: number): string {
    const uuidArray = [];
    const hexDigits = '0123456789abcdef';
    let start: number;

    for (let i = 0; i < 36; i++) {
      start = Math.floor(Math.random() * 0x10);
      uuidArray[i] = hexDigits.substring(start, start + 1);
    }

    uuidArray[14] = '4';
    // eslint-disable-next-line no-bitwise
    start = (uuidArray[19] & 0x3) | 0x8;
    uuidArray[19] = hexDigits.substring(start, start + 1);
    uuidArray[8] = uuidArray[13] = uuidArray[18] = uuidArray[23] = '-';

    return uuidArray.join('').substring(0, length);
  }
}
