import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { VideoService } from 'src/app/services/video/video.service';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { slideInAnimation } from 'src/app/router-animations';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { JobApplication } from 'src/app/models/job-application.model';
import { VIDEO_QUESTIONS_COUNT } from 'src/app/models/video.interface';
import { QUIZ_TYPES } from 'src/app/resources/quiz-types';

@Component({
  selector: 'app-video-questions',
  templateUrl: './video-questions.component.html',
  styleUrls: ['./video-questions.component.scss'],
  animations: [slideInAnimation]
})
export class VideoQuestionsComponent implements OnInit, OnDestroy {

  activeQuestionIndex: number;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private appConfigService: AppConfigService,
    private router: Router,
    private quizStore: QuizStoreService,
    private videoService: VideoService,
    private progressBarService: ProgressBarService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    const { nextQuestion, videoQuestions } = this.appConfigService.config.jobApplication;

    this.quizStore.quiz = {
      type: QUIZ_TYPES.VIDEO_QUESTIONS,
      activeQuestionIndex: nextQuestion,
      ...videoQuestions
    };

    this.checkJobApplicationStatus();
    this.trackAnsweredQuestion();
  }

  trackAnsweredQuestion(): void {
    this.videoService.questionAnswered$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((jobApplication: JobApplication) => {
        this.appConfigService.config.jobApplication = jobApplication;

        if (jobApplication.applicationComplete) {
          this.navigate('/application-complete');
          return;
        }

        if (jobApplication.videoQuestionsComplete) {
          this.navigate('/quiz');
          return;
        }

        this.quizStore.activeQuestionIndex = jobApplication.nextQuestion;
        this.activeQuestionIndex = jobApplication.nextQuestion;
        this.updateProgressBar(this.activeQuestionIndex);
        this.navigate(`/video/answer/${this.activeQuestionIndex}`);
      });
  }

  checkJobApplicationStatus(): void {
    if (this.appConfigService.config.jobApplication.applicationComplete) {
      this.navigate('/application-complete');
      return;
    }

    if (this.appConfigService.config.jobApplication.videoQuestionsComplete) {
      this.navigate('/quiz');
      return;
    }

    this.activeQuestionIndex = -1;
    this.navigate('/video/start');
  }

  navigate(url: string): void {
    this.ngZone.run(() => this.router.navigate([url], { queryParamsHandling: 'merge' }));
  }

  updateProgressBar(activeQuestionIndex: number): void {
    const progress = (100 * (activeQuestionIndex + 1)) / VIDEO_QUESTIONS_COUNT;
    this.progressBarService.updateProgress(progress);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
