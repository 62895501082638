import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { VideoQuestionsComponent } from './video-questions.component';
import { VideoStartComponent } from './video-start/video-start.component';
import { VideoAnswerComponent } from './video-answer/video-answer.component';
import { CanDeactivateComponentGuard } from '../../guards/can-deactivate-component.guard';
import { CustomReuseStrategy } from '../../route-reuse-strategy';

const routes: Routes = [
  {
    path: '',
    component: VideoQuestionsComponent,
    children: [
      {
        component: VideoStartComponent,
        path: 'start',
        data: { reuseRoute: false },
      },
      {
        component: VideoAnswerComponent,
        path: 'answer/:id',
        canDeactivate: [CanDeactivateComponentGuard],
        data: { reuseRoute: true },
      },
    ]
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
  ],
})
export class VideoQuestionsRoutingModule { }
