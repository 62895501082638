import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { VideoService } from 'src/app/services/video/video.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ActivatedRoute, NavigationStart, Params, Router, RouterEvent } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { JobApplication } from 'src/app/models/job-application.model';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import {
  CountdownSettings,
  DEFAULT_COUNTDOWN_TIME,
  MAX_RECORDING_DURATION,
  MAX_VIDEO_UPLOAD_DURATION,
  VideoMode
} from 'src/app/models/video.interface';
import { changeVideoExtension } from '../../shared/video-url-transformation-functions';
import { TranslateService } from '@ngx-translate/core';
import { SetupService } from 'src/app/services/setup.service';
import { Application } from 'src/app/classes/application.class';
import { FlashphonerService } from 'src/app/services/video/flashphoner.service';
import { CheckDeviceService } from 'src/app/services/check-device.service';
import { ConfirmationModalData } from 'src/app/models/modal.interface';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmationModal } from 'src/app/classes/modal.class';
import { ComponentCanDeactivate } from 'src/app/guards/can-deactivate-component.guard';
import { FormBuilder, Validators } from '@angular/forms';
import { linkValidation } from 'src/app/resources/link.validator';
import { Job } from 'src/app/models/job.model';

@Component({
  selector: 'app-video-answer',
  templateUrl: './video-answer.component.html',
  styleUrls: ['./video-answer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoAnswerComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @Input() isApplicationUpdate = false;

  @Output() updateApplication: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class.route-card') card = true;
  @HostBinding('class.upload-in-progress') get uploadClass(): boolean {
    return this.uploadInProgress;
  }

  @ViewChild('recorder') recorderContainer: ElementRef<HTMLDivElement>;
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement: ElementRef<HTMLCanvasElement>;
  @ViewChild('videoUploadInput') videoUploadInput: ElementRef;

  question: string;
  questionIndex: number;
  disableUploadButton = false;
  videoModeEnum = VideoMode;
  videoMode: VideoMode = VideoMode.void;
  // RECORD MODE
  recordingInProgress = false;
  recordingProgress = 0;
  readyToRecord = false;
  playbackProgress = 0;
  // VIDEO RECORDER
  countdownSettings: CountdownSettings = {
    enable: true,
    time: DEFAULT_COUNTDOWN_TIME,
    currentTime: DEFAULT_COUNTDOWN_TIME,
    displayOverlay: false,
  };
  // current time in seconds
  videoProgress = 0;
  recordingInterval: number;
  videoMp4Url = '';

  showUploadVideoModal = false;
  showBackToUploadButton = false;

  fileSizeLimit = 104857600; // ~ 100MB
  allowedFileTypes: string[] = ['video/mp4', 'video/webm', 'video/quicktime', 'video/mpeg', 'video/ogg', 'video/3gpp'];

  uploadInProgress = false;
  uploadProgress = 0;
  uploadProgressInterval: number;
  inProcessing = false;
  showDurationError = false;

  userMedia: MediaStream;

  canDeactivate = true;
  job: Job;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  private _uploadSubscription: Subscription;

  constructor(
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private videoService: VideoService,
    private configService: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private progressBarService: ProgressBarService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private setupService: SetupService,
    private flashphonerService: FlashphonerService,
    private checkDevice: CheckDeviceService,
    private modalService: ModalService,
    private fb: FormBuilder,
  ) { }

  get canvas(): HTMLCanvasElement {
    return this.canvasElement?.nativeElement;
  }

  get recorder(): HTMLDivElement {
    return this.recorderContainer?.nativeElement;
  }

  get video(): HTMLVideoElement {
    return this.videoElement?.nativeElement;
  }

  get stream(): any {
    return this.flashphonerService.stream;
  }

  get loader(): Observable<boolean> {
    return this.loaderService.loader$;
  }

  ngOnInit(): void {
    this.job  = this.configService.config.job;

    this.trackReadyToRecordEvent();
    this.trackVideoStreamingCompleteEvent();
    this.trackLowConnectionQualityEvent();
    this.trackConnectionFailedEvent();
    this.trackRouterEvents();
    this.trackRouteParams();
  }

  trackReadyToRecordEvent(): void {
    this.flashphonerService.readyToRecord$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(() => {
        this.showBackToUploadButton = false;
        this.readyToRecord = true;
        this.cdr.detectChanges();
      });
  }

  trackVideoStreamingCompleteEvent(): void {
    this.flashphonerService.videoStreamingComplete$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(() => {
        if (this.videoMode === VideoMode.record) {
          this.videoMode = VideoMode.review;
        }

        this.cdr.detectChanges();
        this.loaderService.hide();
      });
  }

  trackLowConnectionQualityEvent(): void {
    this.flashphonerService.lowConnectionQuality$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(async () => {
        this.stopRecording();
        await this.resetSettings();
        this.videoMode = VideoMode.record;
        this.cdr.detectChanges();
        await this.startStreaming();
      });
  }

  trackConnectionFailedEvent(): void {
    this.flashphonerService.connectionFailed$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(async (type: 'session' | 'stream') => {
        this.loaderService.show();
        this.readyToRecord = false;
        this.videoMode = VideoMode.record;

        const streamErrorKey = type === 'stream'
          ? 'VIDEO.TOAST_ERROR_CREATING_STREAM_FAILED'
          : 'VIDEO.TOAST_ERROR_STREAMING_FAILED';

        const errorKey =  this.recordingInProgress
          ? 'VIDEO.TOAST_ERROR_RECORDING_STOPPED'
          : streamErrorKey;

        this.recordingInProgress = false;

        this.toastr.error(
          this.translateService.instant(errorKey)
        );

        this.stopRecording();
        await this.startStreaming();
      });
  }

  trackRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(({navigationTrigger}: NavigationStart) => {
        this.canDeactivate = navigationTrigger !== 'popstate';

        if (navigationTrigger === 'popstate') {
          if (this.videoMode === VideoMode.review) {
            this.removeVideo();
            return;
          }

          if (this.videoMode === VideoMode.record) {
            this.videoMode = VideoMode.void;
            this.cdr.detectChanges();
          }
        }
      });
  }

  trackRouteParams(): void {
    const { q1, q2, q3 } = this.configService.config.jobApplication.videoQuestions;
    this.questionIndex = this.route.snapshot.params.id;
    const questions = [q1, q2, q3];

    this.route.params
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({id}: Params) => {
        this.questionIndex = Number(id);
        const progress = (100 * (this.questionIndex + 1)) / 3;
        this.progressBarService.updateProgress(progress);
        this.question = questions[this.questionIndex];
        this.cdr.detectChanges();
      });
  }

  async record(): Promise<void> {
    this.showDurationError = false;
    this.showBackToUploadButton = true;
    this.cdr.detectChanges();

    this.loaderService.show();

    this.videoMode = VideoMode.record;
    const permissionsDenied = await this.checkDevicePermissions();

    if (permissionsDenied) {
      this.loaderService.hide();
      return;
    }

    await this.initializeRecorder();
  }

  async initializeRecorder(): Promise<void> {
    const initialized = this.flashphonerService.initialize();

    if (!initialized) {
      this.toastr.error('Media provider initialization failed');
      return;
    }

    await this.startStreaming();
  }

  async startStreaming(): Promise<void> {
    this.loaderService.show();

    if (this.checkDevice.isSafari()) {
      try {
        await this.flashphonerService.playFirstVideo(this.recorder);
      } catch (error) {
        this.toastr.error(error);
        return;
      }

      this.renderer.setAttribute(
        this.recorder.querySelector('video'),
        'playsinline',
        ''
      );
      this.cdr.detectChanges();
    }

    try {
      this.flashphonerService.createSession(this.recorder, this.renderer);
    } catch (error) {
      this.toastr.error(error);
      this.toastr.error('Failed to create video stream session');
    }

  }

  startRecording(): void {
    this.readyToRecord = false;

    if (this.isApplicationUpdate) {
      this.startRecord();
      return;
    }

    this.setupService.getApplicationInfo()
      .subscribe(({jobApplication}: Application) => {
        if (jobApplication.applicationComplete) {
          this.navigate('/application-complete');
          return;
        }

        if (jobApplication.videoQuestionsComplete) {
          this.navigate('/quiz');
          return;
        }

        this.startRecord();
      });
  }

  startRecord(): void {
    if (!this.countdownSettings.enable) {
      this.sendStartRecordingRequest();
      return;
    }

    this.countdownSettings.displayOverlay = true;
    this.cdr.detectChanges();

    const timerInterval = window.setInterval(() => {
      if (this.countdownSettings.currentTime > 0) {
        this.countdownSettings.currentTime -= 1;
      } else {
        window.clearInterval(timerInterval);
        this.countdownSettings.displayOverlay = false;
        this.countdownSettings.currentTime = this.countdownSettings.time;

        this.sendStartRecordingRequest();
        this.recordingInProgress = true;
      }

      this.cdr.detectChanges();
    }, 1000);
  }

  sendStartRecordingRequest(): void {
    const streamId = this.stream.id();
    const streamName = this.stream.name();

    this.videoService
      .startRecording(streamId, streamName)
      .subscribe((videoUrl: string) => {
        this.videoMp4Url = `${videoUrl}.mp4`;

        this.startRecordingTimer();
        this.cdr.detectChanges();
      });
  }

  startRecordingTimer(): void {
    this.recordingInterval = window
      .setInterval(() => {
        this.videoProgress += 1;
        this.recordingProgress = (100 / MAX_RECORDING_DURATION) * this.videoProgress;

        if (this.videoProgress === MAX_RECORDING_DURATION + 1) {
          this.stopRecording();
        }

        this.cdr.detectChanges();
      }, 1000);
    }

  stopRecording(): void {
    this.stream?.stop();
    this.loaderService.show();
    window.clearInterval(this.recordingInterval);
    this.recordingInProgress = false;
    this.recordingProgress = 0;
    this.disableUploadButton = !this.videoMp4Url;
    this.cdr.detectChanges();
  }

  removeVideo(): void {
    this.loaderService.show();

    this.videoService.removeVideo(this.videoMp4Url)
      .pipe(
        catchError(async (error: HttpErrorResponse) => {
          await this.resetSettings();
          this.loaderService.hide();

          return throwError(() => error);
        })
      )
      .subscribe(async () => {
        await this.resetSettings();
        this.loaderService.hide();
      });
  }

  async resetSettings(): Promise<void> {
    this.videoMode = VideoMode.void;
    this.resetRecordMode();
    this.videoMp4Url = '';
    this.cdr.detectChanges();
  }

  submitAnswer(): void {
    if (this.isApplicationUpdate) {
      this.updateApplication.emit(this.videoMp4Url);
      return;
    }

    this.loaderService.show();

    this.disableUploadButton = true;

    this.videoService
      .submitAnswer(this.videoMp4Url, this.questionIndex)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.toastr.error(
            this.translateService.instant('VIDEO.TOAST_ERROR_SUBMIT_ERROR')
          );
          this.disableUploadButton = false;

          return throwError(() => errorResponse);
        })
      )
      .subscribe((jobApplication: JobApplication) => {
        this.videoService.questionAnswered = jobApplication;
        this.videoMode = VideoMode.void;
        this.loaderService.hide();
      });
  }

  resetRecordMode(): void {
    this.recordingInProgress = false;
    this.recordingProgress = 0;
    this.readyToRecord = false;
    this.videoProgress = 0;
  }

  stopCameraAndMic(): void {
    this.userMedia?.getTracks()
      .forEach(track => track.stop());
  }

  async checkDevicePermissions(): Promise<boolean> {
    if ('mediaDevices' in navigator) {
      let time = 0;
      let interval: number = null;

      if (this.checkDevice.isWebView()) {
        interval = window.setInterval(() => {
          if (time === 5) {
            window.clearInterval(interval);

            this.showPermissionsModal(true);

            return;
          }

          time++;
        }, 1000);
      }

      try {
        this.userMedia = await navigator.mediaDevices.getUserMedia({audio: true, video: true});

        if (time === 5) {
          return true;
        }

        if (interval !== null) {
          window.clearInterval(interval);
        }

        return false;

      } catch (error) {
        if (interval) {
          window.clearInterval(interval);
        }

        this.showPermissionsModal();

        this.cdr.detectChanges();
        return true;
      }
    } else {
      this.toastr.error(
        this.translateService.instant('VIDEO.TOAST_ERROR_STREAMING_UNAVAILABLE')
      );
      return true;
    }
  }

  showPermissionsModal(showWebViewMessage: boolean = false): void {
    const data: ConfirmationModalData = {
      title: 'PERMISSIONS_MODAL.TITLE',
      content: 'PERMISSIONS_MODAL.EXPLANATION',
      confirmBtnTitle: 'VIDEO.RETURN_TO_UPLOAD',
      hideCancelButton: true,
      confirm: () => this.back(),
      close: () => this.back(),
    };

    if (showWebViewMessage) {
      data.content = 'The In-app browser doesn\'t allow the usage of a camera and microphone. Please, open this page in some other browser.';
    }

    this.modalService.addModal(new ConfirmationModal(data));
  }

  navigate(url: string): void {
    this.router.navigate([url], { queryParamsHandling: 'merge' });
  }

  openUploadVideoModal(): void {
    this.showDurationError = false;
    const data: ConfirmationModalData = {
      title: 'VIDEO.UPLOAD_VIDEO_MODAL_TITLE',
      content: 'VIDEO.UPLOAD_VIDEO_MODAL_CONTENT',
      confirmBtnTitle: 'VIDEO.BROWSE',
      cancelBtnTitle: 'SEND_LINK.MODAL.CLOSE_MODAL_BTN',
      confirm: () => this.videoUploadInput.nativeElement.click(),
    };

    this.modalService.addModal(new ConfirmationModal(data));
  }

  openAddLinkModal(): void {
    this.showDurationError = false;
    const data: ConfirmationModalData = {
      title: 'VIDEO.ADD_LINK_MODAL_TITLE',
      content: 'VIDEO.ADD_LINK_MODAL_INFO',
      confirmBtnTitle: 'BUTTONS.SUBMIT',
      cancelBtnTitle: 'SEND_LINK.MODAL.CLOSE_MODAL_BTN',
      form: this.fb.group({
        link: ['', [Validators.required, linkValidation]]
      }),
      confirm: (modal) => this.addLink(modal.data.form.get('link').value),
    };

    this.modalService.addModal(new ConfirmationModal(data));
  }

  addLink(videoLink): void {
    if (this.isApplicationUpdate) {
      this.updateApplication.emit(videoLink);
      return;
    }
    this.videoMp4Url = videoLink;
    this.uploadInProgress = false;
    this.inProcessing = false;
    this.uploadProgress = 0;
    this.submitAnswer();
    this.cdr.detectChanges();
  }

  async uploadVideo(fileInput: HTMLInputElement): Promise<void> {
    this.showUploadVideoModal = false;
    this.cdr.detectChanges();

    const files = fileInput.files;

    if (this.uploadInProgress || files.length === 0) {
      this.loaderService.hide();
      return;
    }

    if (files.length > 1) {
      this.toastr.error(
        this.translateService.instant('VIDEO.ONE_VIDEO_UPLOAD_ALLOWED')
      );
      this.loaderService.hide();
      return;
    }

    const file = files[0];

    if (file.size > this.fileSizeLimit) {
      this.loaderService.hide();
      this.toastr.error(
        this.translateService.instant('VIDEO.FILE_SIZE_SMALLER_THAN')
      );
      return;
    }

    if (!this.allowedFileTypes.includes(file.type)) {
      this.toastr.error(
        this.translateService.instant('VIDEO.FILE_TYPE_NOT_ALLOWED')
      );
      this.loaderService.hide();
      this.cdr.detectChanges();
      return;
    }

    this.uploadInProgress = true;
    this.cdr.detectChanges();

    let duration: number;

    try {
      duration = await this.getDuration(file);
    } catch (err) {
      console.log(err);
      this.uploadInProgress = false;
      this.cdr.detectChanges();
      return;
    }

    fileInput.value = '';

    // webm format returns Infinity value for duration, validation is only on backend side
    if (duration > MAX_VIDEO_UPLOAD_DURATION && duration !== Infinity) {
      this.showDurationError = true;
      this.uploadInProgress = false;
      this.loaderService.hide();
      this.cdr.detectChanges();
      return;
    }

    this.uploadProgress = 0;
    this.cdr.detectChanges();

    this.upload(file);
  }

  upload(file: File): void {
    this._uploadSubscription = this.videoService.uploadVideo(file)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.uploadInProgress = false;
          this.uploadProgress = 0;
          this.inProcessing = false;

          if (errorResponse.error.includes('Video file length is more than 10min.')) {
            this.showDurationError = true;
          } else {
            this.toastr.error(
              this.translateService.instant('VIDEO.UPLOAD_FAILED')
            );
          }

          this.cdr.detectChanges();
          return throwError(() => errorResponse);
        }),
      )
      .subscribe((httpEvent: HttpEvent<string>) => this.handleUploadEvent(httpEvent));
  }

  handleUploadEvent(httpEvent: HttpEvent<string>): void {
    if (httpEvent.type === HttpEventType.Response) {
      const videoUrl = httpEvent.body;
      this.videoMp4Url = changeVideoExtension(videoUrl, 'mp4');
      this.uploadInProgress = false;
      this.inProcessing = false;
      this.uploadProgress = 0;
      this.videoMode = VideoMode.review;
      this.cdr.detectChanges();
    }

    if (httpEvent.type === HttpEventType.UploadProgress && httpEvent.loaded && httpEvent.total) {
      if (this.uploadProgress < 90) {
        this.uploadProgress = Math.round((90 * httpEvent.loaded) / httpEvent.total);
      }

      if (this.uploadProgress === 90) {
        this.inProcessing = true;
        this.setUploadInterval();
      }

      this.cdr.detectChanges();
    }
  }

  setUploadInterval(): void {
    if (this.uploadProgressInterval) {
      return;
    }

    this.uploadProgressInterval = window
      .setInterval(() => {
        if (this.uploadProgress === 100 || !this.uploadInProgress) {
          window.clearInterval(this.uploadProgressInterval);
          this.uploadProgressInterval = 0;
          return;
        }

        this.uploadProgress += 1;
        this.cdr.detectChanges();
      }, 4500);
  }

  getDuration(file: File): Promise<number> {
    const url = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
      const audio = document.createElement('audio');
      audio.muted = true;
      const source = document.createElement('source');
      source.src = url;
      audio.preload= 'metadata';
      audio.appendChild(source);

      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };

      audio.onerror = (error: ProgressEvent<FileReader>) => reject(error);
    });
  }

  cancelUpload(): void {
    this._uploadSubscription.unsubscribe();
    this.uploadInProgress = false;
    this.inProcessing = false;
    this.uploadProgress = 0;
    this.cdr.detectChanges();
  }

  async back(): Promise<void> {
    this.videoMode = VideoMode.void;
    this.disableUploadButton = false;
    this.showBackToUploadButton = false;
    this.stream?.stop();
    window.clearInterval(this.recordingInterval);
    await this.stopCameraAndMic();
    this.loaderService.hide();
  }

  async ngOnDestroy(): Promise<void> {
    this.stream?.stop();
    window.clearInterval(this.recordingInterval);
    await this.stopCameraAndMic();

    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
