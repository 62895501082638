import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Input
} from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { JobApplication } from 'src/app/models/job-application.model';
import { CheckDeviceService } from 'src/app/services/check-device.service';

@Component({
  selector: 'app-video-start',
  templateUrl: './video-start.component.html',
  styleUrls: ['./video-start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoStartComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isUniversalJobUpdate = false;

  @HostBinding('class.route-card')
  get card(): boolean {
    return !this.isUniversalJobUpdate;
  };

  videoUrl: string;
  application: JobApplication;
  isMobile = this.checkDevice.isMobile();

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private configService: AppConfigService,
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private checkDevice: CheckDeviceService
  ) { }

  get questionIndex(): number {
    const { q1answer, q2answer, q3answer } = this.application.videoQuestions;

    if (!q1answer) {
      return 0;
    }

    if (!q2answer) {
      return 1;
    }

    if (!q3answer) {
      return 2;
    }
  }

  ngOnInit(): void {
    this.trackRouterEvents();

    this.application = this.configService.config.jobApplication;
    this.videoUrl = this.application.videoQuestions.video;
    this.cdr.detectChanges();
  }

  trackRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(({navigationTrigger}: NavigationStart) => {
        if (navigationTrigger === 'popstate') {
          this.router.navigate(['/video/start'], { queryParamsHandling: 'preserve' });
        }
      });
  }

  ngAfterViewInit(): void {
    this.loaderService.show();
    this.cdr.detectChanges();
    this.loaderService.hide();
  }

  answerQuestions(): void {
    this.router.navigate([`video/answer/${this.questionIndex}`], { queryParamsHandling: 'merge' });
  }

  sanitizeContent(): SafeHtml {
    const { videoMainMessage } = this.application.videoQuestions;

    let message = videoMainMessage;

    if (!videoMainMessage) {
      message = this.translateService.instant('VIDEO.INTRO_MESSAGE');
    }

    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
