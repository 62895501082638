import { AbstractControl } from '@angular/forms';

export function linkValidation(control: AbstractControl): {[key: string]: boolean} | null {
  // http://, https://, ftp://
  const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

  // www. sans http:// or https://
  const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

  const correct = control.value?.match(urlPattern) || control.value?.match(pseudoUrlPattern);
  return correct ? null : {invalidLink: true};
}
