import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoQuestionsComponent } from './video-questions.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VideoQuestionsRoutingModule } from './video-questions-routing.module';
import { VideoStartComponent } from './video-start/video-start.component';
import { VideoAnswerComponent } from './video-answer/video-answer.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    VideoStartComponent,
    VideoQuestionsComponent,
    VideoAnswerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    VideoQuestionsRoutingModule,
    TranslateModule.forChild(),
    SharedModule
  ],
  exports: [
    VideoStartComponent,
    VideoAnswerComponent,
  ]
})
export class VideoQuestionsModule { }
