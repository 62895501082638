import { ConfirmationModalData } from '../models/modal.interface';
import { AbstractModal } from '../modules/modal/abstract-modal';
import { ConfirmationModalComponent } from '../modules/modal/confirmation-modal/confirmation-modal.component';

export class Modal {

  type: typeof AbstractModal;
  data: any;

  constructor(type: typeof AbstractModal, data?: any) {
    this.type = type;
    this.data = data;
  }
}

export class ConfirmationModal extends Modal {

  data: ConfirmationModalData;

  constructor(data: ConfirmationModalData) {
    super(ConfirmationModalComponent, data);
  }
}
