import { Injectable } from '@angular/core';

enum OS {
  unknown = 0,
  Windows = 1,
  WindowsPhone = 2,
  macOS = 3,
  ios = 4,
  Android = 5,
  Linux = 6
}

enum Browser {
  unknown = 0,
  SamsungBrowser = 1,
  Chromium = 2,
  Firefox = 3,
  Safari = 4,
  edge = 5,
  webView = 6
}

enum iOSDevice {
  iPhone = 1,
  iPad = 2,
  iPod = 3
}

@Injectable({
  providedIn: 'root'
})
export class CheckDeviceService {

  userAgent: string = navigator.userAgent;

  constructor() { }

  createDeviceSignature(): string {
    // Use this when all major browsers implement navigator.userAgentData
    // const signature = this.checkBrowserUserAgentData();2668

    // if (signature) {
    //   console.log((navigator as any).userAgentData);
    //   return signature;
    // }

    return this.checkBrowserUserAgent().replace(/\./g, '_');
  }

  checkBrowserUserAgentData(): string {
    const userAgentData = (navigator as any).userAgentData;

    if (!userAgentData) {
      return '';
    }

    const { brands, platform } = userAgentData;
    const os = +OS[platform];

    const browser = brands
      .find(({brand}) => !!Browser[brand]);

    if (!browser || !os || isNaN(os)) {
      return '';
    }

    return `${this.getOSSignature(os)}br${Browser[browser.brand]}v${browser.version}`;
  }

  checkBrowserUserAgent(): string {
    const osSignature = this.getOSSignature(this.getOS());
    const browserSignature = this.getBrowserSignature();

    return `${osSignature}${browserSignature}`;
  }

  getOSSignature(os: number): string {
    if (os === OS.Windows) {
      return `os${os}v${this.getWindowsVersion()}`;
    }
    if (os === OS.WindowsPhone) {
      return `os${os}v${this.getWindowsPhoneVersion()}v${this.getAndroidVersion()}`;
    }

    if (os === OS.macOS) {
      return `os${os}v${this.getFullMacOSVersion()}`;
    }

    if (os === OS.Android) {
      return `os${os}v${this.getAndroidVersion()}`;
    }

    if (os === OS.ios) {
      return `os${os}v${this.getFulliOSVersion()}`;
    }

    if (os === OS.Linux) {
      return `os${os}vnone`;
    }

    return '';
  }

  getOS(): OS {
    if (this.isWindowsOS()) {
      return OS.Windows;
    }

    if (this.isWindowsPhone()) {
      return OS.WindowsPhone;
    }

    if (this.isMacOS()) {
      return OS.macOS;
    }

    if (this.isAndroid()) {
      return OS.Android;
    }

    if (this.isiOS()) {
      return OS.ios;
    }

    if (this.isLinuxOS()) {
      return OS.Linux;
    }

    return OS.unknown;
  }

  getBrowserSignature(): string {
    if (this.isSamsungBrowser()) {
      return `br${Browser.SamsungBrowser}v${this.getSamsungBrowserVersion()}`;
    }

    if (this.isChrome()) {
      return `br${Browser.Chromium}v${this.getFullChromeVersion()}`;
    }

    if (this.isFirefox()) {
      return `br${Browser.Firefox}v${this.getFirefoxVersion()}`;
    }

    if (this.isSafari()) {
      return `br${Browser.Safari}v${this.getSafariVersion()}`;
    }

    if (this.isEdgeiOS()) {
      return `${Browser.edge}`;
    }

    if (this.isWebView()) {
      return `${Browser.webView}`;
    }

    return `${Browser.unknown}`;
  }

  isWindowsOS(): boolean {
    return /Windows\sNT/i.test(this.userAgent);
  }

  isWindowsPhone(): boolean {
    return /Windows\sPhone/i.test(this.userAgent);
  }

  isMacOS(): boolean {
    return /Macintosh/i.test(this.userAgent);
  }

  isAndroid(): boolean {
    return /Android/i.test(this.userAgent);
  }

  isiOS(): boolean {
    return /iPhone|iPad|iPod/i.test(this.userAgent);
  }

  isLinuxOS(): boolean {
    return /Linux/i.test(this.userAgent);
  }

  isChrome(): boolean {
    return /CriOS|Chrome|Chromium/i.test(this.userAgent);
  }

  isSamsungBrowser(): boolean {
    return /SamsungBrowser/i.test(this.userAgent);
  }

  isFirefox(): boolean {
    return /Firefox|FxiOS/i.test(this.userAgent);
  }

  isEdgeiOS(): boolean {
    return /EdgiOS/i.test(this.userAgent);
  }

  getWindowsVersion(): string {
    const match = this.userAgent.match(/Windows\sNT\s([0-9]+(\.[0-9]+)?)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];

  }

  getWindowsPhoneVersion(): string {
    const match = this.userAgent.match(/Windows\sPhone\s([0-9]+)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }

  getAndroidVersion(): string {
    const match = this.userAgent.match(/Android\s([0-9\.]*)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }

  getAndroidVersionAsNumber(): number {
    const match = this.userAgent.match(/Android\s([0-9\.]*)/i);
    return match ? parseInt(match[1], 10) : 0;
  }

  getFullMacOSVersion(): string {
    const match = this.userAgent.match(/OS\sX\s([0-9]+(_[0-9]+)?(_[0-9]+)?)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }

  getFulliOSVersion(): string {
    const match = this.userAgent.match(/OS\s([0-9]+(_[0-9]+)?(_[0-9]+)?)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }

  getiOSVersionAsNumber(): number {
    const userAgent = this.userAgent.match(/OS\s([0-9]+_?[0-9])/i);

    return userAgent ? parseInt(userAgent[1].replace('_', '.'), 10) : 0;
  }

  getFirefoxVersion(): string {
    const match = this.userAgent.match(/(Firefox|FxiOS)\/([0-9]+)/i);

    if (!match || !match[2]) {
      return '';
    }

    return match[2];
  }

  getSamsungBrowserVersion(): string {
    const match = this.userAgent.match(/SamsungBrowser\/([0-9]+.[0-9]+)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }

  getFullChromeVersion(): string {
    const match = this.userAgent.match(/(CriOS|Chrome|Chromium)\/([0-9]+(.[0-9]+)?(.[0-9]+)?(.[0-9]+)?)/i);

    if (!match || !match[2]) {
      return '';
    }

    return match[2];
  }

  getSafariVersion(): string {
    const match = this.userAgent.match(/Version\/([0-9]+.[0-9].?[0-9]?)/i);

    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }

  getSafariVersionAsNumber(): number {
    const userAgent = this.userAgent.match(/Version\/([0-9]+.[0-9])/i);

    return userAgent ? parseInt(userAgent[1], 10) : 0;
  }

  isMobile(): boolean {
    return this.isAndroid() || this.isiOS();
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(this.userAgent);
  }

  isWebView(): boolean {
    return !!this.userAgent.match(
      /(WebView|(iPhone|iPod|iPad)(?!.*Safari)|Android.*(;\s+wv|Version\/\d.\d\s+Chrome\/\d+(\.0){3})|Linux; U; Android)/gi
    );
  }
}
